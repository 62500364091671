import "./App.css";
import Pages from "./Pages";
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from "./test";

function App() {
  return (
    <div>
      {/* <Test/> */}
      <Pages/>
    </div>
  );
}

export default App;
