import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import "./Pages.css";
import logo from "./Image/logo.png";
import banner from "./Image/banner.png"
import Brief from "./Image/Brief.png"
import card2 from "./Image/card2.png"
import card3 from "./Image/card3.png"
import card4 from "./Image/card4.png"
import Footer from "./Image/Footer.png"
import icon from "./Image/Icon.png"
import lamp from "./Image/Lamp.png"
import Research from "./Image/Research.png"
import Research1 from "./Image/Research1.png"
import security1 from "./Image/security1.png"
import security2 from "./Image/security2.png"
import security3 from "./Image/security3.png"
import Statistic from "./Image/Statistic.png"
import group from "./Image/group.png"
import bg from "./Image/bg.png"
import star from "./Image/star.png"
import feature from "./Image/feature.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AOS from 'aos';
import 'aos/dist/aos.css';
import baseUrl from './constant';
import { Modal, Button, Form } from 'react-bootstrap';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal"
];

const Pages = () => {
  const [plans, setPlans] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [adminModalShow, setAdminModalShow] = useState(false);
  const [getvendorId, setVendorId] = useState('');
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [vendorStatus, setVendorStatus] = useState(null);
  const [errors, setErrors] = useState({});


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '',
    country: 'India',
    state: '',
    city: '',
    ZipCode: '',
    address: '',
    gst: ''
  });

  const stepsRef = useRef([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const closeNavbar = () => setIsOpen(false);

  const resetForms = () => {
    setFormData({
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      phoneNumber: '',
      country: 'India',
      state: '',
      city: '',
      ZipCode: '',
      address: '',
      gst: ''
    });
    setPassword('');
    setConfirmPassword('');
    setOtpValue('');
    setSelectedPlan(null);
    setSelectedPlanId(null);
  };

  useEffect(() => {
    AOS.init();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          }
        });
      },
      { threshold: 0.2 }
    );

    stepsRef.current.forEach((step, index) => {
      if (step) {
        step.style.transitionDelay = `${index * 0.2}s`; // Adds delay based on index
        observer.observe(step);
      }
    });
    fetchPlans()
    return () => {
      stepsRef.current.forEach((step) => {
        if (step) {
          observer.unobserve(step);
        }
      });
    };

  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate firstName
    if (!formData.firstName) {
      newErrors.firstName = 'First name is required.';
    } else if (formData.firstName.length < 3 || formData.firstName.length > 15) {
      newErrors.firstName = 'First name must be between 3 and 15 characters.';
    }

    // Validate lastName
    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required.';
    }

    // Validate email
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!validator.isEmail(formData.email)) {
      newErrors.email = 'Please provide a valid email.';
    }

    // Validate phoneNumber
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.';
    } else if (formData.phoneNumber.length !== 10) {
      newErrors.phoneNumber = 'Please provide a valid phone number.';
    }

    // Validate state
    if (!formData.state) {
      newErrors.state = 'State is required.';
    }

    // Validate city
    if (!formData.city) {
      newErrors.city = 'City is required.';
    }

    // Validate ZipCode
    if (!formData.ZipCode) {
      newErrors.ZipCode = 'Zip code is required.';
    }

    // GST is optional, no validation needed for it

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Exit the function if validation fails
    }
    try {
      const response = await axios.post(`${baseUrl}/superadmin/vendor/create`, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.company,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        zipcode: formData.ZipCode,
        address: formData.address,
        gst: formData.gst
      });

      const vendorData = response.data.data;
      const vendorId = vendorData._id;
      setVendorId(vendorId);


      if (response.status === 201) {
        const vendorData = response.data.data;
        const vendorId = vendorData._id;
        setVendorId(vendorId); // Store the vendor ID
        setVendorStatus(response.data.status);
        console.log("Vendor successfully registered:", response.data);

        // Call the OTP send API since the registration was successful
        const otpResponse = await axios.post(`${baseUrl}/superadmin/vendor/sendOtp`, {
          email: formData.email
        });

        if (otpResponse.status === 200) {
          console.log("OTP successfully sent to:", formData.email);
          setModalShow(false); // Hide the registration modal
          setOtpModalShow(true); // Show the OTP modal
        } else {
          console.error("Failed to send OTP:", otpResponse.data);
        }

      } else {
        console.error("Failed to register vendor:", response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.status === 4 ||
        error.response && error.response.data && error.response.data.status === 2) {
        setVendorStatus(error.response.data.status)
        console.log("error.response.data.status", error.response.data.status)
        const vendorData = error.response.data.data;
        const vendorId = vendorData._id;
        console.log("vendorId", vendorId)
        setVendorId(vendorId);

        try {
          const otpResponse = await axios.post(`${baseUrl}/superadmin/vendor/sendOtp`, {
            email: formData.email
          });

          if (otpResponse.status === 200) {
            console.log("OTP successfully sent to:", formData.email);
            setModalShow(false); // Hide the registration modal
            setOtpModalShow(true); // Show the OTP modal
          } else {
            console.error("Failed to send OTP:", otpResponse.data);
          }
        } catch (otpError) {
          console.error("Error resending OTP:", otpError.response ? otpError.response.data : otpError);
        }

      } else {
        console.error("Error registering vendor or sending OTP:", error.response ? error.response.data : error);
      }
    }
  };



  const handleOtpChange = (e) => {
    setOtpValue(e.target.value);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (otpValue.length !== 6 || !/^\d+$/.test(otpValue)) {
      console.error("Invalid OTP format");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/superadmin/confirm-otp`, {
        email: formData.email,
        otp: otpValue
      });
      console.log("asdfafd", vendorStatus)

      if (response.status === 200) {
        if (vendorStatus === 2) {
          console.log("vendorStatus", vendorStatus)
          console.log("selectedPlan", selectedPlan)
          console.log(getvendorId, "getvendorId")
          if (selectedPlan && getvendorId) {
            console.log("selectedPlan", selectedPlan)
            console.log("selectedPlan", getvendorId)
            const selectedPlanAmount = selectedPlan.planSellingPrice * 100; // Convert to paise
            const razorpayKey = "rzp_test_xmoTkQyHp0cExq"; // Razorpay Key
            handlePayment(getvendorId, selectedPlanAmount, razorpayKey);
          } else {
            console.error("Selected plan or vendor ID is missing.");
          }

        } else {
          // Otherwise, open the admin modal
          setOtpModalShow(false);
          setAdminModalShow(true);
        }
      } else {
        console.error("Failed to verify OTP:", response.data);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.response ? error.response.data : error);
    }
  };




  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/get/plans`);
      if (response.status === 200) {
        setPlans(response.data.data);
      } else {
        console.error('Failed to fetch plans');
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') setPassword(value);
    if (name === 'confirmPassword') setConfirmPassword(value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);  // Make sure `confirmPassword` is part of the state
  };

  const handleAdminSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match before submitting
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!selectedPlanId) {
      alert("Please select a plan");
      return;
    }

    try {
      // Ensure getvendorId is set
      if (!getvendorId) {
        throw new Error("Vendor ID is not set.");
      }

      // Step 1: Create admin using existing vendorId
      const adminResponse = await axios.post(`${baseUrl}/superadmin/admin/create`, {
        vendorId: getvendorId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        password: password
      });

      console.log("Admin successfully created:", adminResponse.data);

      // Check if admin creation was successful
      if (adminResponse.status === 200 || adminResponse.status === 201) {
        // Proceed with next steps, such as fetching plan details and handling payment
        const planResponse = await axios.get(`${baseUrl}/user/get/plans`);
        const selectedPlan = planResponse.data.data.find(plan => plan._id === selectedPlanId);

        if (!selectedPlan) {
          throw new Error("Selected plan not found.");
        }

        const amount = selectedPlan.planSellingPrice * 100;  // Convert amount to paise
        const razorpayKey = "rzp_test_xmoTkQyHp0cExq";  // Dynamic Razorpay key

        // Step 3: Proceed to payment with Razorpay
        handlePayment(getvendorId, amount, razorpayKey);
      } else {
        console.error("Admin creation failed:", adminResponse.data);
        // Handle the failure case, e.g., display an error message
        alert("Admin creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during admin creation or plan fetching:", error);
      // Handle the error case, e.g., display an error message
      alert("An error occurred. Please try again.");
    }
  };
  const handlePayment = (getvendorId, amount, razorpayKey) => {
    // console.log(orderId,"abc");

    const options = {
      key: razorpayKey,  // Dynamic Razorpay Key
      amount: amount,    // Dynamic amount in paise
      currency: "INR",
      name: "DukaanApp",
      description: "Plan Purchase",
      handler: async function (response) {
        console.log("Payment successful:", response.razorpay_payment_id);

        // Call the backend API with payment ID and vendor ID
        await handleRazorpayApi(getvendorId, response.razorpay_payment_id, amount / 100);  // Convert amount back to INR
      },
      prefill: {
        email: formData.email,
        contact: formData.phoneNumber
      },
      theme: {
        color: "#3399cc"
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleRazorpayApi = async (getvendorId, paymentId, amount) => {
    try {
      const response = await axios.post(`${baseUrl}/vendor/razorpay/${getvendorId}`, {
        amount: amount,  // Amount in INR
        razorpayPaymentId: paymentId  // Razorpay Payment ID
      });

      if (response.status === 200) {
        console.log("Razorpay API called successfully:", response.data);
        // const orderId = response.data.order.id;
        // console.log("Razorpay Order ID:", orderId);
        alert("Payment was successful! Thank you for your purchase.");
        await capturePayment(paymentId, amount);

      } else {
        console.error("Failed to call Razorpay API:", response.data);
      }
    } catch (error) {
      console.error("Error calling Razorpay API:", error);
    }
  };

  const capturePayment = async (paymentId, amount) => {
    console.log("Capturing Payment:", paymentId, amount);

    try {
      // Step 1: Capture the payment
      const captureResponse = await axios.post(`${baseUrl}/vendor/razorpaycapture`, {
        amount: amount * 100, // Convert to paise
        paymentId: paymentId,
      });

      if (captureResponse.status === 200) {
        console.log("Payment captured successfully:", captureResponse.data);

        const razorpayOrderId = captureResponse.data.data.id; // This is payment ID, not order ID
        const planId = selectedPlanId;
        const vendorId = getvendorId;
        const razorpayKey = "rzp_test_xmoTkQyHp0cExq";

        // Prepare the body data for the PATCH request
        const subscriptionData = {
          planId: planId,
          razorpayOrderId: razorpayOrderId ? razorpayOrderId : null, // Send null if no orderId
          amount: amount,
          razorpayKey: razorpayKey,
        };

        // Step 3: Send PATCH request to add the subscription
        const subscriptionResponse = await axios.patch(
          `${baseUrl}/vendor/add/subscription/${vendorId}`,
          subscriptionData
        );

        if (subscriptionResponse.status === 200) {
          console.log("Subscription added successfully:", subscriptionResponse.data);
          alert("Subscription successfully added!");
        } else {
          console.error("Failed to add subscription:", subscriptionResponse.data);
          alert("Subscription failed. Please try again.");
        }

      } else {
        console.error("Failed to capture payment:", captureResponse.data);
      }
    } catch (error) {
      console.error("Error capturing payment or adding subscription:", error);
    }
  };




  const handleModalOpen = (plan) => {
    setSelectedPlan(plan);
    setModalShow(true);
  };


  const handleClose = () => {
    setModalShow(false);
    setOtpModalShow(false);
    setAdminModalShow(false);
    resetForms();
  };




  return (
    <>
      <div className=" main-container">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light nav-content">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src={logo} alt="Logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                aria-controls="navbarNav"
                aria-expanded={isOpen}
                aria-label="Toggle navigation"
              >
                <span
                  className="navbar-toggler-icon"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns%3D%27http%3A//www.w3.org/2000/svg%27 viewBox%3D%270 0 30 30%27%3E%3Cpath stroke%3D%27rgba%28255%2C 255%2C 255%2C 1%29%27 stroke-width%3D%272%27 stroke-linecap%3D%27round%27 stroke-miterlimit%3D%2710%27 d%3D%27M4 7h22M4 15h22M4 23h22%27/%3E%3C/svg%3E")`,
                  }}
                ></span>
              </button>
              <div className={`collapse navbar-collapse nav-header ${isOpen ? 'show' : ''}`} id="navbarNav">
                <a className="navbar-brand2" href="#">
                  <img src={logo} alt="Logo" />
                </a>
                <ul className="navbar-nav custom-navbar">
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={closeNavbar}>
                      HOME
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about-us" onClick={closeNavbar}>
                      ABOUT US
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#feature" onClick={closeNavbar}>
                      FEATURES
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pricing" onClick={closeNavbar}>
                      PRICING
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact" onClick={closeNavbar}>
                      CONTACT US
                    </a>
                  </li>
                </ul>
                <div className="auth-buttons">
                  <a href="https://admin.mydukaanapp.com/" target="_blank" rel="noopener noreferrer" className="btn login-btn">
                    LOG IN
                  </a>
                  <a href="#pricing" className="btn btn-primary sing-up-btn">
                    SIGN UP
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <section className="hero-section text-center home">
          <img
            src={star}
            className="random-img mt-3 mx-auto d-block"
          />
          <div className="container-fluid hero">
            <div className="row align-items-center hero-container" style={{ textAlign: 'left' }}>
              <div className="col-md-5 hero-div">
                <img
                  src={star}
                  className="random-img mt-3 mx-auto d-block"
                />
                <h1
                  className="hero-div-name"
                  style={{ fontFamily: 'Raleway', textAlign: 'left', color: 'white' }}
                >
                  Create Your Potential Business with Our Innovative Website &
                  Mobile App
                </h1>
                <p>
                  A Budget-friendly Mobile App and Website for each shopkeeper
                  to go online
                </p>
                <a href="#pricing" className="btn getstart-btn">
                  GET STARTED
                </a>
              </div>
              <div className="col-md-6">
                <img
                  src={banner}
                  alt="Hero Image"
                  className="img-fluid"
                />
              </div>

            </div>
            <img
              src={star}
              className="random-img mt-3 mx-auto d-block"
            />
          </div>
        </section>
        <section id="about-us">
          <div className="container about-us">
            <div className="second-heading" style={{ color: 'white' }}>
              <h1>WHY MYDUKAAN</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-3 col-sm-12 mt-5">
                <div className="card text-center second-card " data-aos="fade-up" // Animate from the left
                  data-aos-duration="1000" style={{
                    borderTopLeftRadius: '160px',
                    borderTopRightRadius: '160px',
                    height: '50vh'

                  }}>
                  <img
                    src="https://www.bamboonine.co.uk/wp-content/uploads/2023/01/Design.png"
                    className="card-img-top img-fluid"
                  />
                  <div className="card-body feature-cards">
                    <h2 className="card-title">Customised</h2>
                    <p className="card-text">
                    You have the power to fully customise your website and
                    mobile app branding directly from the admin panel. Tailor every element—from logos and colors to fonts and layouts.
                    </p>
                  </div>
                  <div className="number one">
                    <h3>01</h3>
                    <div className="num-circle" id="numb1">
                      <i className="fa-solid fa-arrow-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-12 mt-5">
                <div className="card text-center second-card " data-aos="fade-up" // Animate from the left
                  data-aos-duration="1000"
                  style={{
                    borderTopLeftRadius: '160px',
                    borderTopRightRadius: '160px',
                    height: '50vh'

                  }}>
                  <img
                    src={card2}
                    className="card-img-top image-fluid"
                  />
                  <div className="card-body feature-cards">
                    <h2 className="card-title">Faster</h2>
                    <p className="card-text">
                    Our platform is optimised to deliver blazing-fast
                    load times for both your website and mobile app, ensuring a smooth and seamless experience for your users
                    </p>
                  </div>
                  <div className="number two">
                    <h3>02</h3>
                    <div className="num-circle" id="numb2">
                      <i className="fa-solid fa-arrow-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-12 mt-5">
                <div className="card text-center second-card " data-aos="fade-up" // Animate from the left
                  data-aos-duration="1000"
                  style={{
                    borderTopLeftRadius: '160px',
                    borderTopRightRadius: '160px',
                    height: '50vh'

                  }}>
                  <img
                    src={card3}
                    className="card-img-top img-fluid"
                  />
                  <div className="card-body feature-cards">
                    <h2 className="card-title">Affordable</h2>
                    <p className="card-text">
                    We believe that top-tier digital solutions shouldn’t come with a hefty price tag.
                     That’s why we offer high-quality, fully customised websites and apps at prices that fit your budget.
                    </p>
                  </div>
                  <div className="number three">
                    <h3>03</h3>
                    <div className="num-circle" id="numb3">
                      <i className="fa-solid fa-arrow-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 col-sm-12 mt-5">
                <div className="card text-center second-card" data-aos="fade-up" // Animate from the left
                  data-aos-duration="1000"
                  style={{
                    borderTopLeftRadius: '160px',
                    borderTopRightRadius: '160px',
                    height: '50vh'

                  }}>
                  <img
                    src={card4}
                    className="card-img-top img-fluid"
                  />
                  <div className="card-body feature-cards">
                    <h2 className="card-title">Easy</h2>
                    <p className="card-text">
                    With our platform, you can subscribe and set up your website and 
                    mobile app in just a few simple steps, all from one intuitive admin panel
                    </p>
                  </div>
                  <div className="number four">
                    <h3>04</h3>
                    <div className="num-circle" id="numb4">
                      <i className="fa-solid fa-arrow-up"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="feature">
          <div className="clip">
            <img src={feature} className="feature-img" />
          </div>
        </section>

        <section className='step-card-header'>
          <h1 style={{
            textAlign: 'center',
            marginTop: '3rem',
            fontSize: '55px',
            fontFamily: 'Raleway',
            color: 'white',
            marginBottom: '10rem',
          }}>
            How We Work
          </h1>
          <div className="steps-container row" style={{ fontFamily: 'Raleway' }}>
            <div className="col-md-4 col-lg-2 col-sm-12 step-whole">
              <div className="step-wrapper" ref={(el) => (stepsRef.current[0] = el)}>
                <div className="step-title" id="title1" >Get Started with Your Subscription</div>
                <div className="dotted-line" id="line1"></div>
                <div className="dotted-circle" id="dot1"></div>
                <div className="icon-circle">
                  <img src={Brief} alt="Step 1" />
                </div>
                <div className="step-card" id="card1">
                  <h3>Subscribe the plan</h3>
                  <p>
                    Subscribe the plan. Sign in to access your dashboard, customize
                    your preferences, and start enjoying exclusive content and
                    services.
                  </p>
                </div>
                <div className="step-number" id="num1">01</div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12 step-whole">
              <div className="step-wrapper" ref={(el) => (stepsRef.current[1] = el)}>
                <div className="step-title" id="title2">Pick Your Template</div>
                <div className="dotted-line" id="line2"></div>
                <div className="dotted-circle" id="dot2"></div>
                <div className="icon-circle">
                  <img src={Statistic} alt="Step 2" />
                </div>
                <div className="step-card" id="card2">
                  <h3>Choose the perfect template</h3>
                  <p>
                    Choose the perfect template for your website or mobile app. Browse
                    our collection to find designs that suit your style and needs.
                  </p>
                </div>
                <div className="step-number" id="num2">02</div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12  step-whole">
              <div className="step-wrapper" ref={(el) => (stepsRef.current[2] = el)}>
                <div className="step-title" id="title3">Manage Your Branding</div>
                <div className="dotted-line" id="line3"></div>
                <div className="dotted-circle" id="dot3"></div>
                <div className="icon-circle">
                  <img src={lamp} alt="Step 3" />
                </div>
                <div className="step-card" id="card3">
                  <h3>Take control of your brand identity</h3>
                  <p>
                    Take control of your brand identity with our tools. Customise logos, colours, and fonts to align with your vision. Ensure your website and mobile app reflect your unique brand
                  </p>
                </div>
                <div className="step-number" id="num3">03</div>
              </div>
            </div>
            <div className=" col-md-4 col-lg-2  col-sm-12  step-whole">
              <div className="step-wrapper" ref={(el) => (stepsRef.current[3] = el)}>
                <div className="step-title" id="title4">Manage Your Product</div>
                <div className="dotted-line" id="line4"></div>
                <div className="dotted-circle" id="dot4"></div>
                <div className="icon-circle" >
                  <img src={Research} alt="Step 4" />
                </div>
                <div className="step-card" id="card4">
                  <h3>Effortlessly manage your products</h3>
                  <p>
                    Effortlessly manage your products across both your website and mobile app. Update listings, track inventory, and streamline your sales process. Keep your offerings consistent and up-to-date
                  </p>
                </div>
                <div className="step-number" id="num4">04</div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 col-sm-12  step-whole">

              <div className="step-wrapper" id="step-card-wrapper" ref={(el) => (stepsRef.current[4] = el)}>
                <div className="step-title" id="title5">Launch</div>
                <div className="dotted-line" id="line5" ></div>
                <div className="dotted-circle" id="dot5"></div>
                <div className="icon-circle">
                  <img src={Research1} alt="Step 5" />
                </div>
                <div className="step-card" id="step-card5">
                  <h3>Launch your website</h3>
                  <p>
                    Get ready! We're launching your website and mobile app on your domain. Enjoy seamless integration, custom branding, and a smooth launch experience. Your online presence is about to go live!
                  </p>
                </div>
                <div className="step-number" id="num5">05</div>
              </div>
            </div>
          </div>
        </section>
        <section className="security-section  py-5">
          <div className="container">
            <h2 className="text-center mb-5 security-heading">
              Your Security Matters: Robust Protection for Your Website & Mobile
              App
            </h2>
            <div className="row justify-content-md-center">
              {/* <!-- Security Card 1 --> */}
              <div className="col-md-4">
                <div className="mb-5 shadow-sm moving-border">
                  <div className="card-body security-card">
                    <div className="security-icon mb-3">
                      <img
                        src={security1}
                        alt="Secure Hosting and SSL Encryption"
                        style={{ margin: '10px' }}
                      />
                    </div>
                    <h3 className="card-title">
                      Secure Hosting and SSL Encryption
                    </h3>
                    <p>
                      Ensure your landing page is hosted on a secure server with
                      HTTPS enabled. SSL encryption helps to protect the data
                      exchanged between users and your website.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Security Card 2 --> */}
              <div className="col-md-4">
                <div className="mb-4 shadow-sm moving-border">
                  <div className="card-body security-card">
                    <div className="security-icon mb-3">
                      <img
                        src={security2}
                        alt="Data Privacy Policy"
                        style={{ margin: '10px' }}
                      />
                    </div>
                    <h3 className="card-title">Data Privacy Policy</h3>
                    <p>
                      Clearly state your data privacy policy, explaining how
                      user information will be collected, used, and protected.
                      Assure users that their data will be kept confidential.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- Security Card 3 --> */}
              <div className="col-md-4">
                <div className="mb-4 shadow-sm moving-border">
                  <div className="card-body  security-card">
                    <div className="security-icon mb-3">
                      <img
                        src={security3}
                        alt="Two-Factor Authentication (2FA)"
                        style={{ margin: '10px' }}
                      />
                    </div>
                    <h3 className="card-title">
                      Two-Factor Authentication (2FA)
                    </h3>
                    <p>
                      If users need to create accounts, offer optional 2FA to
                      add an extra layer of security to their accounts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid  forwho-container ">
          <h1 className="text-center" style={{ color: 'white' }}>FOR WHOM</h1>
          <div className="categories-wrapper d-none d-md-block">
            <div className="categories">
              {/* <!-- Food Category --> */}
              <div className="category food">
                <div className="header">FOOD</div>
                <div className="content text-white">
                  <ul>
                    <li>Grocery</li>
                    <li>Bakers</li>
                    <li>Sweets</li>
                    <li>Namkeen</li>
                    <li>Ready to eat</li>
                    <li>Dairy Products</li>
                    <li>Dry Fruit Seller</li>
                    <li>Masale/Spices</li>
                  </ul>
                </div>
              </div>
              {/* <!-- Lifestyle Accessories Category --> */}
              <div className="category lifestyle-accessories">
                <div className="header">LIFESTYLE ACCESSORIES</div>
                <div className="content text-white">
                  <ul>
                    <li>Clothing</li>
                    <li>Jewellery</li>
                    <li>Shoes</li>
                    <li>Bags</li>
                    <li>Perfume</li>
                    <li>Dress Material</li>
                    <li>Watch</li>
                    <li>Glasses</li>
                  </ul>
                </div>
              </div>
              {/* <!-- Goods Category --> */}
              <div className="category goods">
                <div className="header">GOODS</div>
                <div className="content text-white">
                  <ul>
                    <li>Gifting</li>
                    <li>Chair</li>
                    <li>Furnishing</li>
                    <li>Furniture</li>
                    <li>Book</li>
                    <li>Stationery</li>
                    <li>Toys Factory</li>
                  </ul>
                </div>
              </div>
              {/* <!-- Accessories Category --> */}
              <div className="category accessories">
                <div className="header">ACCESSORIES</div>
                <div className="content text-white">
                  <ul>
                    <li>Car Rental</li>
                    <li>Car Decor</li>
                    <li>Car Accessories</li>
                    <li>Electronics</li>
                    <li>Electrical</li>
                    <li>Computer</li>
                    <li>Mobile</li>
                    <li>Printing</li>
                    <li>Hardware</li>
                  </ul>
                </div>
              </div>
              {/* <!-- Others Category --> */}
              <div className="category others">
                <div className="header">OTHERS</div>
                <div className="content text-white">
                  <ul>
                    <li>Medicine</li>
                    <li>Cleaning</li>
                    <li>Pooja Bhandar</li>
                    <li>Agro Mart</li>
                    <li>Flower Decor</li>
                    <li>Dog Food</li>
                    <li>White Goods</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Accordion for Mobile and Tablet --> */}
          <div className="accordion d-md-none" id="categoriesAccordion">
            <div className="card food">
              <div className="card-header" id="headingFood">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFood"
                    aria-expanded="true"
                    aria-controls="collapseFood"
                  >
                    FOOD
                  </button>
                </h5>
              </div>
              <div
                id="collapseFood"
                className="collapse show"
                aria-labelledby="headingFood"
                data-parent="#categoriesAccordion"
              >
                <div className="card-body">
                  <ul>
                    <li>Grocery</li>
                    <li>Bakers</li>
                    <li>Sweets</li>
                    <li>Namkeen</li>
                    <li>Ready to eat</li>
                    <li>Dairy Products</li>
                    <li>Dry Fruit Seller</li>
                    <li>Masale/Spices</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Repeat for other categories with corresponding IDs and targets -->
                <!-- Lifestyle Accessories --> */}
            <div className="card lifestyle-accessories">
              <div className="card-header" id="headingLifestyle">
                <h5 className="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseLifestyle"
                    aria-expanded="false"
                    aria-controls="collapseLifestyle"
                  >
                    LIFESTYLE ACCESSORIES
                  </button>
                </h5>
              </div>
              <div
                id="collapseLifestyle"
                className="collapse"
                aria-labelledby="headingLifestyle"
                data-parent="#categoriesAccordion"
              >
                <div className="card-body">
                  <ul>
                    <li>Clothing</li>
                    <li>Jewellery</li>
                    <li>Shoes</li>
                    <li>Bags</li>
                    <li>Perfume</li>
                    <li>Dress Material</li>
                    <li>Watch</li>
                    <li>Glasses</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Repeat for other categories -->
                <!-- Goods --> */}
            <div className="card goods">
              <div className="card-header" id="headingGoods">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseGoods"
                    aria-expanded="false"
                    aria-controls="collapseGoods"
                  >
                    GOODS
                  </button>
                </h5>
              </div>
              <div
                id="collapseGoods"
                className="collapse"
                aria-labelledby="headingGoods"
                data-parent="#categoriesAccordion"
              >
                <div className="card-body">
                  <ul>
                    <li>Gifting</li>
                    <li>Chair</li>
                    <li>Furnishing</li>
                    <li>Furniture</li>
                    <li>Book</li>
                    <li>Stationery</li>
                    <li>Toys Factory</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Accessories --> */}
            <div className="card accessories">
              <div className="card-header" id="headingAccessories">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseAccessories"
                    aria-expanded="false"
                    aria-controls="collapseAccessories"
                  >
                    ACCESSORIES
                  </button>
                </h5>
              </div>
              <div
                id="collapseAccessories"
                className="collapse"
                aria-labelledby="headingAccessories"
                data-parent="#categoriesAccordion"
              >
                <div className="card-body">
                  <ul>
                    <li>Car Rental</li>
                    <li>Car Decor</li>
                    <li>Car Accessories</li>
                    <li>Electronics</li>
                    <li>Electrical</li>
                    <li>Computer</li>
                    <li>Mobile</li>
                    <li>Printing</li>
                    <li>Hardware</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Others --> */}
            <div className="card others">
              <div className="card-header" id="headingOthers">
                <h5 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOthers"
                    aria-expanded="false"
                    aria-controls="collapseOthers"
                  >
                    OTHERS
                  </button>
                </h5>
              </div>
              <div
                id="collapseOthers"
                className="collapse"
                aria-labelledby="headingOthers"
                data-parent="#categoriesAccordion"
              >
                <div className="card-body">
                  <ul>
                    <li>Medicine</li>
                    <li>Cleaning</li>
                    <li>Pooja Bhandar</li>
                    <li>Agro Mart</li>
                    <li>Flower Decor</li>
                    <li>Dog Food</li>
                    <li>White Goods</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="pricing">
          <div className="container-fluid bg-blue pricing-container">
            {/* <!-- Header Section --> */}
            <div className="pricing-wrapper">
              <div className="text-center text-black pricing-text " style={{ fontFamily: 'Raleway' }}>
                <h1 className="pricing-header text-black ">PRICING</h1>
                <p className="pricing-subheader">
                  Affordable pricing for all retail business owners
                </p>
              </div>

              {/* <!-- Pricing Content Section --> */}
              <div className="container plan-box">
                <div className="row">
                  {/* <!-- Image Section --> */}
                  <div className="col-md-12 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                    <div className="plan-container">
                      <div className='text-areas'>
                        <h1>Choose Best Plan For <br /> Your Business</h1>
                        <p>
                          Try out our platform for a full year and discover the
                          plan that best suits your business needs. Explore our
                          range of options to find the perfect fit for your goals
                        </p>
                        <div className="arrow"><FontAwesomeIcon icon={faArrowRight} /></div>
                      </div>
                      <div className="image-container">
                        <img
                          src={group}
                          alt="Person Image"
                          id="person"
                        />
                        <div className="plan-options">
                          <div className="plan-option">
                            <div className="icon red"></div>
                            <img src={bg} />
                          </div>
                          <div className="plan-option">
                            <div className="icon green"></div>
                            <img src={bg} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Cards Section: Wrapped in Flex Container for Equal Height -->
                  <!-- <div className="col-md-8 "> -->
                  <!-- Basic Plan Card --> */}
                  {plans.map((plan, index) => (
                    <div className="col-md-6 col-lg-4 mb-4 d-flex" key={plan._id}>
                      <div className="card pricing-card flex-fill">
                        <div className="card-body height-cls">
                          <h5 className="card-title">{plan.planName.toUpperCase()}</h5>
                          <h3>
                            ₹{plan.planSellingPrice} <span className="yearly">/year</span>
                          </h3>
                          {/* <div className="plan-icon">
                            <img
                              src={plan.planImage}
                              alt={`${plan.planName} Plan Icon`}
                              className="img-fluid card-img"
                            />
                          </div> */}
                          <div className="plan-icon">
                            <img
                              src={icon}
                              alt="Basic Plan Icon"
                              className="img-fluid card-img"
                            />
                          </div>

                          <div dangerouslySetInnerHTML={{ __html: plan.planDescription }} />
                          <div className='mt-auto'>
                            <button
                              className="btngap btn btn-primary d-grid gap-2 d-md-block btn-cls-color"
                              onClick={() => {
                                setSelectedPlanId(plan._id);
                                handleModalOpen(plan);
                              }}
                            >
                              GET STARTED
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact">
          <div className="container-fluid my-5 contact-container">
            <div className="row justify-content-center" >
              {/*  Left Contact Information Column  */}
              <div
                className="col-md-5 left-contact"
                data-aos="fade-right" // Animate from the left
                data-aos-duration="1000" style={{ marginTop: "65px", marginLeft: "10px",padding:"15px" }}

              >
                <div className="left rounded text-white ">
                  <h1 className='h1-cls'>Let's talk with Us</h1>
                  <p>We'd love to know what you're thinking.</p>
                  <h2>Contact Info:</h2>
                  <ul className="list-unstyled text-white" style={{ lineHeight: '35px' }}>
                    <li>
                      <strong style={{ fontWeight: "600" }}>Phone Number:</strong> (+91) 99799 79100
                    </li>
                    <li>
                      <strong>Email:</strong> info@techgigs.in
                    </li>
                    <li>
                      <strong>Map Street:</strong> Kothrud, Pune, Maharashtra,
                      India-411038
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- Right Contact Form Column --> */}
              <div className="col-md-6">
                <div className="right rounded contact">
                  <form>
                    <div className="form-group text-white">
                      <label for="full-name ">Full Name:</label>
                      <input
                        type="text"
                        className="form-control new-form-class"
                        id="full-name"
                        placeholder="Input your full name in here"
                        required
                      />
                    </div>
                    <div className="form-group text-white">
                      <label for="email">Email Address:</label>
                      <input
                        type="email"
                        className="form-control new-form-class"
                        id="email"
                        placeholder="Input your email address in here"
                        required
                      />
                    </div>
                    <div className="form-group text-white">
                      <label for="mobile-number">Mobile Number:</label>
                      <input
                        type="text"
                        className="form-control new-form-class"
                        id="mobile-number"
                        placeholder="Input your mobile number in here"
                        required
                      />
                    </div>
                    <div className="form-group text-white">
                      <label for="message">Message:</label>
                      <textarea
                        id="message"
                        className="form-control new-form-class"
                        placeholder="Write your messages in here"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                    <div className="d-grid gap-2  col-xl-6 col-sm-12 mx-auto">
                      <button
                        type="submit"
                        className="btn  btn-primary btn-send btn-cls-color"
                      >
                        Send Messages
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="text-black">
          <div className="container-fluid">
            <div className="accordion d-md-none" id="companyAccordion">
              <div className="card food bg-black" style={{ backgroundColor: 'rgb(37, 36, 44)' }}>
                <div className="card-header card-for-head" style={{ backgroundColor: 'rgb(37, 36, 44)', borderColor: 'transparent' }} id="headingInfo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseInfo"
                      aria-expanded="true"
                      aria-controls="collapseInfo"
                    >
                      Company Info
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseInfo"
                  className="collapse show"
                  aria-labelledby="headingInfo"
                  data-parent="#companyAccordion"
                >
                  <div className="card-body card-body2">
                    <img src={Footer} alt="Footer" />
                    <p>
                      Introducing MyDukaan, the ultimate cross-selling solution
                      tailored for small businesses in the e-commerce world!
                      With our Website and Mobile App, small businesses can now
                      effortlessly boost sales by offering personalized
                      cross-sell suggestions to their customers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card lifestyle-accessories" style={{ backgroundColor: 'rgb(37, 36, 44)' }}>
                <div className="card-header card-for-head" style={{ backgroundColor: 'rgb(37, 36, 44)', borderColor: 'transparent' }} id="headingPolicy">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsePolicy"
                      aria-expanded="false"
                      aria-controls="collapsePolicy"
                    >
                      Policy
                    </button>
                  </h5>
                </div>
                <div
                  id="collapsePolicy"
                  className="collapse"
                  aria-labelledby="headingPolicy"
                  data-parent="#companyAccordion"
                >
                  <div className="card-body policy-card">
                    <ul>
                      <li>
                        <a href="#">Term & Condition</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Return Policy</a>
                      </li>
                      <li>
                        <a href="#">Disclaimer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card goods" style={{ backgroundColor: 'rgb(37, 36, 44)' }}>
                <div className="card-header card-for-head" style={{ backgroundColor: 'rgb(37, 36, 44)', borderColor: 'transparent' }} id="headingCompany">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseCompany"  // Link to collapsible section
                      aria-expanded="false"
                      aria-controls="collapseCompany"  // Control the corresponding collapse section
                    >
                      Company
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseCompany"  // ID for the collapsible section
                  className="collapse"
                  aria-labelledby="headingCompany"
                  data-parent="#companyAccordion"
                >
                  <div className="card-body">
                    <ul>
                      <li>
                        <a href="#about-us">About Us</a>
                      </li>
                      <li>
                        <a href="#feature">Feature</a>
                      </li>
                      <li>
                        <a href="#pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card accessories" style={{ backgroundColor: 'rgb(37, 36, 44)' }}>
                <div className="card-header card-for-head" style={{ backgroundColor: 'rgb(37, 36, 44)', borderColor: 'transparent' }} id="headingHelp">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseHelp"
                      aria-expanded="false"
                      aria-controls="collapseHelp"
                    >
                      Help & Contact
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseHelp"
                  className="collapse"
                  aria-labelledby="headingHelp"
                  data-parent="#companyAccordion"
                >
                  <div className="card-body">
                    <p>
                      Email:{" "}
                      <a href="mailto:info@techgigs.in">info@techgigs.in</a>
                    </p>
                    <p>Contact: +91 99799 79100</p>
                    <p>
                      Address: Office No: 19,20,21, 4th Floor, Suvarna
                      Commercial Complex, Karve Road, Kothrud, Pune,
                      Maharashtra, India-411038
                    </p>
                  </div>
                </div>
              </div>

              <div className="card others" style={{ backgroundColor: 'rgb(37, 36, 44)' }}>
                <div className="card-header card-for-head" style={{ backgroundColor: 'rgb(37, 36, 44)', borderColor: 'transparent' }} id="headingFollow">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFollow"
                      aria-expanded="false"
                      aria-controls="collapseFollow"
                    >
                      Follow Us
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFollow"
                  className="collapse"
                  aria-labelledby="headingFollow"
                  data-parent="#companyAccordion"
                >
                  <div className="card-body">
                    <div className="social-icons">
                      <a href="https://www.instagram.com/mydukaan1" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <!-- Footer sections for desktop view --> */}
            <div className="d-none d-md-flex justify-content-between">
              <div className="footer-section company-info col-3 px-5 bg-black">
                <img src={Footer} />
                <p>
                  Introducing MyDukaan, the ultimate cross-selling solution
                  tailored for small businesses in the e-commerce world! With
                  our Website and Mobile App, small businesses can now
                  effortlessly boost sales by offering personalized cross-sell
                  suggestions to their customers.
                </p>
              </div>
              <div className="footer-section policy px-5 bg-black">
                <h3>Policy</h3>
                <ul>
                  <li>
                    <a href="#" className="nav-link1">
                      Term & Condition
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link1">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link1">
                      Return Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="nav-link1">
                      Disclaimer
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-section company px-5 bg-black">
                <h3>Company</h3>
                <ul>
                  <li>
                    <a href="#about-us" className="nav-link1">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#feature" className="nav-link1">
                      Feature
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" className="nav-link1">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="nav-link1">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-section contact-help col-3 px-4 bg-black">
                <h3>Help & Contact</h3>
                <p>
                  Email: <a href="mailto:info@techgigs.in">info@techgigs.in</a>
                </p>
                <p>Contact: +91 99799 79100</p>
                <p>
                  Address: Office No: 19,20,21, 4th Floor, Suvarna Commercial
                  Complex, Karve Road, Kothrud, Pune, Maharashtra, India-411038
                </p>
              </div>
              <div className="footer-section follow-us bg-black px-5">
                <h3>Follow Us</h3>
                <div className="social-icons">
                  <a href="https://www.instagram.com/mydukaan1" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center py-2 text-white">
            <small>&copy; 2024 MyDukaan. All rights reserved.</small>
          </div>
        </footer>
      </div>

      <PlanModal
        show={modalShow}
        onHide={handleClose}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        formData={formData}
        errors={errors}
      />
      <OtpModal
        show={otpModalShow}
        onHide={handleClose}
        handleOtpSubmit={handleOtpSubmit}
        handleOtpChange={handleOtpChange}
        otpValue={otpValue}
      />
      <AdminCreationModal
        show={adminModalShow}
        onHide={handleClose}
        handleAdminSubmit={handleAdminSubmit}
        handlePasswordChange={handlePasswordChange}
        handleConfirmPasswordChange={handleConfirmPasswordChange}
        email={formData.email}  // Pass the prefilled email
      />

    </>
  );
}

const PlanModal = ({ show, onHide, handleSubmit, handleChange, formData, errors }) => (
  <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered scrollable backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Signup</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        {/* First Name Field */}
        <div className='display-cls'>
          <Form.Group className="mb-4 form-check" controlId="formFirstName">
            <Form.Control
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
          </Form.Group>

          {/* Last Name Field */}
          <Form.Group className="mb-4 form-check" controlId="formLastName">
            <Form.Control
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
          </Form.Group>
        </div>
        {/* Company Field */}
        <Form.Group className="mb-4" controlId="formCompany">
          <Form.Control
            type="text"
            placeholder="Company Name"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
          />
          {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
        </Form.Group>

        <div className='display-cls'>
          <Form.Group className="mb-4 form-check" controlId="formEmail">
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </Form.Group>

          {/* Phone Number Field */}
          <Form.Group className="mb-4 form-check" controlId="formPhoneNumber">
            <Form.Control
              type="text"
              placeholder="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
          </Form.Group>
        </div>

        {/* Country and State Selector */}
        <div className='display-cls'>
          <Form.Group className="mb-4 cls-con" controlId="formCountry">
            <Form.Control
              type="text"
              placeholder="Country"
              name="country"
              value="India"
              readOnly
              required
            />
          </Form.Group>

          {/* State Selector (Dynamic) */}
          <Form.Group className="mb-4 cls-state" controlId="formState">
            <Form.Select
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            >
              <option value="">Select your state</option>
              {/* Dynamically generate options from indianStates */}
              {indianStates.map((state, index) => (
                <option key={index} value={state}>{state}</option>
              ))}
            </Form.Select>
            {errors.state && <div className="text-danger">{errors.state}</div>}
          </Form.Group>
        </div>
        <Form.Group className="mb-4" controlId="formAddress">
          <Form.Control
            type="text"
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
          {errors.address && <div className="text-danger">{errors.address}</div>}
        </Form.Group>

        <div className='display-cls'>
          <Form.Group className="mb-4 form-check" controlId="formCity">
            <Form.Control
              type="text"
              placeholder="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
            {errors.city && <div className="text-danger">{errors.city}</div>}
          </Form.Group>

          {/* Zip Code Field */}
          <Form.Group className="mb-4 form-check" controlId="formZipCode">
            <Form.Control
              type="text"
              placeholder="Zipcode"
              name="ZipCode"
              value={formData.ZipCode}
              onChange={handleChange}
              required
            />
            {errors.ZipCode && <div className="text-danger">{errors.ZipCode}</div>}
          </Form.Group>
        </div>

        <Form.Group className="mb-4" controlId="formGST">
          <Form.Control
            type="text"
            placeholder="GST Number (Optional)"
            name="gst"
            value={formData.gst}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="btn-register">
          Register
        </Button>
      </Form>
    </Modal.Body>
    {/* <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer> */}
  </Modal>
);

const OtpModal = ({ show, onHide, handleOtpSubmit, handleOtpChange, otpValue }) => (
  <Modal backdrop="static" keyboard={false} show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered scrollable>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Enter OTP</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleOtpSubmit}>
        <Form.Group className="mb-4" controlId="formOtp">
          <Form.Control
            type="text"
            placeholder="Enter OTP"
            name="otp"
            value={otpValue}
            onChange={handleOtpChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3 btn-register">
          Submit OTP
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
);

const AdminCreationModal = ({
  show,
  onHide,
  handleAdminSubmit,
  handlePasswordChange,
  handleConfirmPasswordChange,
  email,
}) => {

  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility
  return (
    <Modal backdrop="static" keyboard={false} show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAdminSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              defaultValue={email} // Prefill the email
              readOnly
            />
          </Form.Group>

          <Form.Group controlId="password" className="position-relative">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
              name="password"
              placeholder="Enter password"
              onChange={handlePasswordChange}
              required
            />
            <a
              href="#password"
              onClick={(ev) => {
                ev.preventDefault();
                setShowPassword(!showPassword); // Toggle password visibility
              }}
              className="position-absolute password-toggle-icon"
              style={{ cursor: 'pointer', top: "35px", right: "10px" }} // Adjust the top and right position as needed
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </a>
          </Form.Group>

          <Form.Group controlId="confirmPassword" className="position-relative">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type={showConfirmPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
              name="confirmPassword"
              placeholder="Confirm password"
              onChange={handleConfirmPasswordChange}
              required
            />
            <a
              href="#confirmPassword"
              onClick={(ev) => {
                ev.preventDefault();
                setShowConfirmPassword(!showConfirmPassword); // Toggle confirm password visibility
              }}
              className="position-absolute password-toggle-icon"
              style={{ cursor: 'pointer', top: "35px", right: "10px" }} // Adjust the top and right position as needed
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </a>
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3 btn-register">
            Pay
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Pages;
